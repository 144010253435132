import React from 'react'
import PropTypes from 'prop-types'

import { LocaleContext } from '../Layout'
import * as S from './styled'

const ListItem = ({ node, categories }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const {
    image: {
      localFile: {
        childImageSharp: {
          fluid: { src },
        },
      },
    },
  } = node.data
  return (
    <S.Item>
      <S.BackgroundImage src={src}>
        <S.Overlay />
        {categories.map((cat, i) => (
          <S.Categories key={cat}>
            {!!i && ' / '} <span>{cat} </span>
          </S.Categories>
        ))}
        <S.Divider />
        <S.Headline>{node.data.title.text}</S.Headline>
        <S.Description>{node.data.description}</S.Description>
        <S.Date>{node.data.date}</S.Date>
        <S.StyledLink to={`/${node.uid}`}>
          <S.Button>{i18n.buttonPost}</S.Button>
        </S.StyledLink>
      </S.BackgroundImage>
    </S.Item>
  )
}

export default ListItem

ListItem.propTypes = {
  node: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
}
