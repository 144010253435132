import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { BodyText, Document, Image } from '../slices'
import { prism } from '../styles'

const Content = styled.div`
  ${prism}
  padding: 6rem 0;
  width: 100%;
  h3 {
    padding-top: 24px;
  }

  p,
  li {
    letter-spacing: -0.003em;
    --baseline-multiplier: 0.179;
    --x-height-multiplier: 0.35;
    font-size: 1rem;
    line-height: 1.58;
    padding-bottom: 1rem;
    code {
      padding: 0.2rem 0.5rem;
      margin: 0.5rem 0;
    }
  }
  p {
    :first-of-type {
      padding-top: 32px;
    }
  }
  blockquote {
    margin-left: 0;
    padding-left: 1.45rem;
    border-left: 2px solid ${props => props.theme.colors.primary};
    p {
      font-size: 19px;
      font-style: italic;
    }
  }
  img {
    padding: 48px 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
      padding: 32px 0;
    }
  }
`

const SliceZone = ({ allSlices }) => {
  const slice =
    allSlices &&
    allSlices.map(s => {
      switch (s.slice_type) {
        // These are the API IDs of the slices
        case 'text':
          return <BodyText key={s.id} input={s} />
        case 'document':
          return <Document key={s.id} input={s} />
        case 'image':
          return <Image key={s.id} input={s} />
        default:
          return null
      }
    })
  return <Content>{slice}</Content>
}

export default SliceZone

SliceZone.propTypes = {
  allSlices: PropTypes.array.isRequired,
}
