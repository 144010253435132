import styled from '@emotion/styled'

import LocalizedLink from '../LocalizedLink'
import backgroundOverlay from '../../../content/images/background-posts.svg'
import { Button as DefaultButton } from '../UI/Buttons'

export const List = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  list-style-type: none;
  margin-left: 0;
`

export const Item = styled.li`
  position: relative;
  max-width: calc(50% - 16px);
  min-height: 416px;
  width: 100%;

  margin-bottom: 1.45rem;
  border-radius: 4px;

  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    max-width: 100%;
    min-height: 260px;
  }
`

export const BackgroundImage = styled.div`
  position: relative;
  z-index: 0;
  height: 100%;
  width: 100%;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  padding: 40px 48px;
  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    padding: 16px;
  }
`
export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(${backgroundOverlay});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  z-index: 1;
`
export const Headline = styled.p`
  font-size: 1.8rem;
  font-weight: bold;
  position: relative;
  z-index: 2;
  padding-top: 60px;
  color: ${props => props.theme.colors.bg};
  margin-bottom: 0;
  a {
    color: ${props => props.theme.colors.bg};
    font-style: normal;
    font-weight: normal;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    font-size: 1.5rem;
    padding-top: 32px;
  }
`

export const Divider = styled.div`
  background: ${({ theme }) => theme.colors.bg};
  border-radius: 2px;
  height: 2px;
  width: 56px;
  margin-top: 16px;
`
export const Categories = styled.span`
  font-size: 0.8rem;
  color: ${props => props.theme.colors.bg};
  letter-spacing: 2.8px;
  font-weight: bold;
  text-transform: uppercase;
`
export const StyledLink = styled(LocalizedLink)`
  position: relative;
  z-index: 2;
  font-size: 2.369rem;
  color: ${props => props.theme.colors.bg};
  font-style: normal;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.777rem;
  }
`

export const Description = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.bg};
  padding: 16px 0;
  position: relative;
  z-index: 2;
`

export const Button = styled(DefaultButton)`
  font-size: 1rem;
  max-width: 216px;
  padding: 2px 26px;
`

export const Date = styled.p`
  font-size: 0.8rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.bg};
  position: relative;
  z-index: 2;
  padding-bottom: 16px;
`
