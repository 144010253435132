/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { SEO } from '../components'

import { LocaleContext } from '../components/Layout'
import bubbles from '../../content/images/bubbles.svg'

import * as S from './styled'

const Litter = ({ data: { prismicLitter }, location, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const isPt = lang.locale === 'pt-pt'

  const { data } = prismicLitter
  const { father, mother, long_description: description, origin, title, date_of_birth: dob, kittens } = data
  const {
    image: { url: imageFather },
    name: nameFather,
  } = father.document[0].data
  const {
    image: { url: imageMother },
    name: nameMother,
  } = mother.document[0].data

  return (
    <>
      <SEO
        title={`${data.title} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale}
        desc={data.description}
        node={prismicLitter}
      />

      <S.Wrapper>
        <S.InnerWrapper>
          <S.Row>
            <S.Bubbles src={bubbles} alt="Bengalesisnika" />
            <S.Col>
              <S.ThumbCircle src={imageFather} />
            </S.Col>
            <S.Col>
              <S.Title>{i18n.father}</S.Title>
              <S.SubTitle>{nameFather}</S.SubTitle>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.ThumbCircle src={imageMother} />
            </S.Col>
            <S.Col>
              <S.Title>{i18n.mother}</S.Title>
              <S.SubTitle>{nameMother}</S.SubTitle>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.SectionTitle>{title}</S.SectionTitle>
          </S.Row>
          <S.Row>
            <S.Text dangerouslySetInnerHTML={{ __html: description.html }} />
          </S.Row>
          {kittens.map(kitten => {
            const {
              description1: descriptionKitten,
              image: imageKitten,
              name: nameKitten,
              reserve_state: state,
            } = kitten
            const isAvailable = state === 'available'
            const underEvaluation = state === 'evaluation'

            return (
              <S.Card key={nameKitten}>
                <S.CardRow>
                  <S.ThumbCol>
                    <S.Thumbnail src={imageKitten.url} alt={nameKitten} />
                  </S.ThumbCol>
                  <S.ContentCol>
                    <S.KittenTitle>{nameKitten}</S.KittenTitle>
                    <S.CardText>{descriptionKitten}</S.CardText>
                    <S.InnerRow>
                      <S.Col>
                        <S.Label>{i18n.dob}</S.Label>
                        <S.CardText>{dob}</S.CardText>
                        <S.Label>{i18n.origin}</S.Label>
                        <S.CardText>{origin}</S.CardText>
                      </S.Col>
                      <S.Col>
                        {isAvailable ? (
                          <S.Link to={isPt ? '/reservation' : '/en/reservation'}>
                            <S.Button>{i18n.reserve}</S.Button>
                          </S.Link>
                        ) : (
                          <S.Title>{underEvaluation ? i18n.underEvaluation : i18n.reserved}</S.Title>
                        )}
                      </S.Col>
                    </S.InnerRow>
                  </S.ContentCol>
                </S.CardRow>
              </S.Card>
            )
          })}
          <S.LastRow>
            <S.Link to={isPt ? `/${i18n.droplink3}` : `/en/${i18n.droplink3}`}>
              <S.LittersButton>{i18n.moreLitters}</S.LittersButton>
            </S.Link>
          </S.LastRow>
        </S.InnerWrapper>
      </S.Wrapper>
    </>
  )
}

export default Litter

Litter.propTypes = {
  data: PropTypes.shape({
    prismicLitter: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query LitterBySlug($uid: String!, $locale: String!) {
    prismicLitter(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      data {
        father {
          document {
            data {
              image {
                url
              }
              name
            }
          }
        }
        mother {
          document {
            data {
              image {
                url
              }
              name
            }
          }
        }
        date_of_birth
        origin
        kittens {
          image {
            url
          }
          name
          description1
          reserve_state
        }
        long_description {
          html
        }
        title
      }
    }
  }
`
