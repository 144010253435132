import styled from '@emotion/styled'
import { Link as GatsbyLink } from 'gatsby'

import { Col as DefaultCol, Row as DefaultRow } from '../components/UI/Grid'
import { Button as DefaultButton } from '../components/UI/Buttons'

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  padding: ${({ isPost }) => (isPost ? '64px 16px 0 16px' : '64px 16px')};
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 48px 16px;
  }
`

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`
export const Title = styled.h2`
  font-size: 2rem;
  line-height: 1.4;

  font-weight: bold;
  color: ${({ theme }) => theme.colors.orange};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

export const SectionTitle = styled(Title)`
  margin-top: ${({ isPost }) => (isPost ? '0' : '88px')};
  color: ${({ theme }) => theme.colors.black};
`

export const SubTitle = styled.h3`
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: normal;

  color: ${({ theme }) => theme.colors.black};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

export const Row = styled(DefaultRow)`
  position: relative;
  align-self: center;
  align-items: center;
`

export const Col = styled(DefaultCol)``

export const ThumbCol = styled(Col)`
  max-width: 338px;
  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    margin-right: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    max-width: 100%;
    width: 100%;
    flex-grow: 1;
    margin-right: 0;
  }
`

export const ContentCol = styled(Col)`
  padding-left: 16px;
  flex-grow: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    padding-left: 0;
  }
`

export const ThumbCircle = styled.div`
  border-radius: 50%;
  height: 195px;
  width: 195px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  margin: 32px 24px 8px 0;
`

export const Bubbles = styled.img`
  position: absolute;
  max-width: 156px;
  top: 100px;
  right: 16px;
`

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.bg};
  border: 1px solid #ecebf5;
  box-shadow: 0 2px 16px 0 rgba(21, 30, 41, 0.06);
  border-radius: 4px;
  margin-top: 32px;
  padding: 20px;

  :first-of-type {
    margin-top: 108px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    max-width: 100%;
  }
`

export const CardRow = styled(Row)`
  flex-wrap: nowrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    justify-content: flex-start;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-wrap: wrap;
  }
`

export const InnerRow = styled(Row)`
  justify-content: space-between;
`

export const Button = styled(DefaultButton)`
  width: 100%;
  justify-content: center;
  min-width: 216px;
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    max-width: 100%;
  }
`
export const KittenTitle = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
  padding: 16px 0;
`

export const Thumbnail = styled.div`
  min-width: 338px;
  min-height: 285px;
  height: 100%;
  border-radius: 4px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    margin-right: 16px;
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-right: 0;
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    min-width: 100%;
  }
`

export const Link = styled(GatsbyLink)`
  margin-top: 16px;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    justify-content: flex-start;
  }
`

export const Label = styled.p`
  padding: 16px 0 0 0;
  color: ${({ theme }) => theme.colors.orange};
`

export const Text = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 2rem;

  padding: 32px 0;
`

export const CardText = styled(Text)`
  padding: 0;
`

export const LastRow = styled(Row)`
  margin-top: 48px;
`
export const LittersButton = styled(Button)`
  padding: 24px;
  max-width: 400px;
`

/* POST */

export const TextCol = styled(Col)`
  max-width: 800px;
`
export const Date = styled.p`
  font-size: 0.6rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  letter-spacing: 2px;
`

export const BubblesPost = styled.img`
  position: absolute;
  max-width: 156px;
  top: 0;
  right: 16px;
`

export const NewsTitle = styled(Title)`
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.black};
`
