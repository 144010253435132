import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { LocaleContext } from '../components/Layout'

const ExternalLink = styled.a`
  margin-top: 16px;
  display: inline-block;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.orange};
  }
`

const Document = ({ input }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <ExternalLink target="_blank" rel="noopener noreferrer" href={input.primary.link.url}>
      {i18n.openLink}
    </ExternalLink>
  )
}

export default Document

Document.propTypes = {
  input: PropTypes.object.isRequired,
}
