import React from 'react'
import PropTypes from 'prop-types'

import ListItem from './ListItem'

import * as S from './styled'

const Listing = ({ posts }) => (
  <S.List>
    {posts.map(post => {
      let categories = false
      if (post.node.data.categories[0].category) {
        categories = post.node.data.categories.map(c => c.category.document[0].data.name)
      }
      return <ListItem key={post.node.uid} node={post.node} categories={categories} />
    })}
  </S.List>
)

export default Listing

Listing.propTypes = {
  posts: PropTypes.array.isRequired,
}
